<template>
  <component is="b-card">
    <b-modal
      ref="add-recovery-modal"
      ok-only
      :ok-title="$t('Add')"
      centered
      size="lg"
      :title="$t('Add recovery')"
      no-close-on-backdrop
      @ok="addRecovery"
      @hidden="addRecoveryModalHidden"
    >
      <b-row>
        <b-col>
          <b-form-group :label="$t('Select client')" label-for="client">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="clients"
              v-model="selectedAddRecoveryClient"
              label="name"
              input-id="client"
              @search="fetchClientOptions"
              @input="clientSelected"
            />
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group :label="$t('Select situation')" label-for="client">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="situations"
              v-model="selectedAddRecoverySituation"
              @search="fetchSituatiomOptions"
              label="ref"
              input-id="client"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group :label="$t('Select annexe')" label-for="annexes">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="annexes"
              label="name"
              v-model="selectedAnnexe"
              input-id="annexes"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            :label="$t('Select payment method')"
            label-for="payment_method"
          >
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="paymentMethods"
              v-model="selectedAddRecoveryPaymentMethod"
              label="name"
              input-id="payment_method"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group :label="$t('Amount')" label-for="amount">
            <b-form-input type="number" v-model="amount" input-id="amount" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group :label="$t('Ref Bon Caisse')" label-for="ref">
            <b-form-input id="ref" v-model="refbonCaiseValue" debounce="500" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="selectedAddRecoveryPaymentMethod">
        <template
          v-if="
            selectedAddRecoveryPaymentMethod.name === 'Cheque' ||
            selectedAddRecoveryPaymentMethod.name === 'Effet'
          "
        >
          <b-col>
            <b-form-group
              :label="$t('Ref Cheque/Effet')"
              label-for="cheque-effet"
            >
              <b-form-input v-model="refChequeEffet" input-id="cheque-effet" />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group :label="$t('Date Echeance')" label-for="ref">
              <flat-pickr
                class="form-control"
                :config="{ dateFormat: 'Y-m-d' }"
                v-model="selectedDateEcheance"
              />
            </b-form-group>
          </b-col>
        </template>
      </b-row>
    </b-modal>
    <b-card no-body class="mb-0">
      <b-row class="mb-2">
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="openAddRecoveryModal"
          >
            {{ $t("Add recovery") }}
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group :label="$t('Ref')" label-for="ref">
            <b-form-input id="ref" v-model="refValue" debounce="500" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group :label="$t('Select client')" label-for="client">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="clients"
              v-model="selectedClient"
              label="name"
              input-id="client"
              @search="fetchClientOptions"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            :label="$t('Select payment method')"
            label-for="payment_method"
          >
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="paymentMethods"
              v-model="selectedPaymentMethod"
              label="name"
              input-id="payment_method"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
        ref="refRecoveryListTable"
        class="position-relative"
        :items="fetchRecoverys"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('No matching records found')"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Actions -->
        <template #cell(amount)="data"> {{ data.item.amount }} DH </template>
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item>
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ $t("Edit") }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{
              $t("Pagination text", [dataMeta.from, dataMeta.to, dataMeta.of])
            }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRecoverys"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </component>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BForm,
  BFormGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { avatarText } from "@core/utils/filter";
import useRecoveryList from "./useRecoveryList";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import instance from "@/libs/axios";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BForm,
    BFormGroup,

    vSelect,
    flatPickr,
  },
  data() {
    return {
      clients: [],
      annexes: [],
      situations: [],
      paymentMethods: [],
      selectedAddRecoveryClient: null,
      selectedAddRecoveryPaymentMethod: null,
      selectedAnnexe: null,
      selectedAddRecoverySituation: null,
      refbonCaiseValue: null,
      refChequeEffet: null,
      amount: null,
      selectedDateEcheance: null,
    };
  },
  async mounted() {
    try {
      const res = await Promise.all([
        instance.get("/parameters/clients/"),
        instance.get("/parameters/payment-methods/"),
      ]);

      this.clients = res[0].data.results;
      this.paymentMethods = res[1].data;
    } catch (err) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t("Error"),
          text: err.message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    }
  },
  methods: {
    async clientSelected(client) {
      this.selectedAddRecoverySituation = null;
      this.selectedAnnexe = null;
      if (!client) return;
      try {
        const res2 = await instance.get("/parameters/clients-annexes/", {
          params: {
            all: true,
            client_id: this.selectedAddRecoveryClient.id,
          },
        });
        this.annexes = res2.data;

        const res3 = await instance.get("/recoveries/situations/", {
          params: {
            client: this.selectedAddRecoveryClient.id,
            add_recovery: true,
          },
        });

        this.situations = res3.data.results;
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    addRecoveryModalHidden() {
      this.selectedAddRecoveryClient = null;
      this.selectedAddRecoveryPaymentMethod = null;
      this.refbonCaiseValue = null;
      this.refChequeEffet = null;
      this.amount = null;
      this.selectedDateEcheance = null;
    },
    openAddRecoveryModal() {
      this.$refs["add-recovery-modal"].show();
    },
    async addRecovery(bvEvent) {
      if (!this.selectedAddRecoveryClient) {
        bvEvent.preventDefault();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Alert"),
            text: this.$t("The Client is required"),
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
        return;
      }
      if (!this.selectedAddRecoverySituation) {
        bvEvent.preventDefault();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Alert"),
            text: this.$t("The Client situation is required"),
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
        return;
      }

      if (!this.selectedAddRecoveryPaymentMethod) {
        bvEvent.preventDefault();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Alert"),
            text: this.$t("The Payment method is required"),
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
        return;
      }
      if (!this.amount) {
        bvEvent.preventDefault();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Alert"),
            text: this.$t("The amount is required"),
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
        return;
      }
      if (!this.refbonCaiseValue) {
        bvEvent.preventDefault();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Alert"),
            text: this.$t("The ref bon caisse is required"),
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
        return;
      }

      if (
        this.selectedAddRecoveryPaymentMethod.name === "Cheque" ||
        this.selectedAddRecoveryPaymentMethod.name === "Effet"
      ) {
        if (!this.refChequeEffet) {
          bvEvent.preventDefault();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Alert"),
              text: this.$t("The Ref Cheque/Effet is required"),
              icon: "AlertTriangleIcon",
              variant: "warning",
            },
          });
          return;
        }
        if (!this.selectedDateEcheance) {
          bvEvent.preventDefault();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Alert"),
              text: this.$t("The Date Echeance is required"),
              icon: "AlertTriangleIcon",
              variant: "warning",
            },
          });
          return;
        }
      }

      try {
        await instance.post("/recoveries/", {
          amount: this.amount,
          ref_bon_caisse: this.refbonCaiseValue,
          client_id: this.selectedAddRecoveryClient.id,
          date_echeance: this.selectedDateEcheance,
          payment_method_id: this.selectedAddRecoveryPaymentMethod.id,
          ref_cheque_effet: this.refChequeEffet,
          client_annexe_id: this.selectedAnnexe?.id,
          situation_id: this.selectedAddRecoverySituation.id,
        });

        this.amount = null;
        this.refbonCaiseValue = null;
        this.selectedAddRecoveryClient = null;
        this.selectedDateEcheance = null;
        this.selectedAddRecoveryPaymentMethod = null;
        this.refChequeEffet = null;
        this.selectedAnnexe = null;
        this.selectedAddRecoverySituation = null;

        this.refetchData();

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Success"),
            text: this.$t("Recovery has been created"),
            icon: "CheckIcon",
            variant: "success",
          },
        });
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },

    fetchClientOptions(search, loading) {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(async () => {
        try {
          loading(true);
          const res = await instance.get("/parameters/clients/", {
            params: { search },
          });
          this.clients = res.data.results;
        } catch (err) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Error"),
              text: err.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        } finally {
          loading(false);
        }
      }, 500);
    },
    fetchSituatiomOptions(search, loading) {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(async () => {
        try {
          loading(true);
          const res = await instance.get("/recoveries/situations/", {
            params: { ref: search },
          });
          this.situations = res.data.results;
        } catch (err) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Error"),
              text: err.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        } finally {
          loading(false);
        }
      }, 500);
    },
  },
  setup() {
    const {
      fetchRecoverys,
      tableColumns,
      perPage,
      currentPage,
      totalRecoverys,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refRecoveryListTable,
      refetchData,
      selectedClient,
      refValue,
      selectedPaymentMethod,
    } = useRecoveryList();

    return {
      fetchRecoverys,
      tableColumns,
      perPage,
      currentPage,
      totalRecoverys,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refRecoveryListTable,
      refetchData,
      // Filter
      avatarText,
      selectedClient,
      refValue,
      selectedPaymentMethod,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
