import { ref, watch, computed } from "@vue/composition-api";

// Notification
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import instance from "@/libs/axios";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import { useToast } from "vue-toastification/composition";

export default function useRecoveryList() {
  // Use toast
  const { t } = useI18nUtils();
  const toast = useToast();

  const refRecoveryListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    // { key: "id" },
    { key: "client.name", label: t("Client") },
    { key: "situation.ref", label: t("Situation") },
    { key: "client_annexe.name", label: t("Client Annexe") },
    { key: "amount", label: t("Amount") },
    { key: "ref_bon_caisse", label: t("Bon Caisse") },
    { key: "payment_method.name", label: t("Payment Method") },
    { key: "ref_cheque_effet", label: t("Ref Cheque/Effet") },
    { key: "date_echeance", label: t("Date echeance") },
  ];
  const perPage = ref(10);
  const totalRecoverys = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const refValue = ref("");
  const selectedClient = ref(null);
  const selectedPaymentMethod = ref(null);
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);

  const dataMeta = computed(() => {
    const localItemsCount = refRecoveryListTable.value
      ? refRecoveryListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRecoverys.value,
    };
  });

  const refetchData = () => {
    refRecoveryListTable.value.refresh();
  };

  watch(
    [
      currentPage,
      perPage,
      searchQuery,
      selectedClient,
      refValue,
      selectedPaymentMethod,
    ],
    () => {
      refetchData();
    }
  );

  const fetchRecoverys = (ctx, callback) => {
    instance
      .get("/recoveries/", {
        params: {
          page: currentPage.value,
          client: selectedClient.value?.id,
          ref: refValue.value,
          payment_method: selectedPaymentMethod.value?.id,
        },
      })
      .then((response) => {
        const { count, results } = response.data;
        totalRecoverys.value = count;
        callback(results);
      })
      .catch((err) => {
        const error = err.response
          ? Object.values(err.response.data)[0][0]
          : err.message;

        toast({
          component: ToastificationContent,
          props: {
            title: t("Error"),
            text: error ?? t("An error has occurred. Please try again"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  return {
    fetchRecoverys,
    tableColumns,
    perPage,
    currentPage,
    totalRecoverys,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refRecoveryListTable,
    refetchData,
    t,
    selectedClient,
    refValue,
    selectedPaymentMethod,
  };
}
